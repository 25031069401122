import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectUserState } from '../redux/slices/user.slice';
import { getUserTokenData } from '../utils/get-user-token-data';
import {
    useAwardPrizeMutation,
    useCancelPrizeMutation,
    useGetPrizesDataQuery,
} from '../redux/api/prize';

import { getRtkQueryErrors } from '../utils/get-rtk-query-errors';
import useTranslations from './use-translations';

type TUsePrizeQueries = 'data';

interface IUsePrizeConfig {
    queries?: TUsePrizeQueries[];
}

export const usePrize = ({ queries = [] }: IUsePrizeConfig | undefined = {}) => {
    const t = useTranslations('RTKQueryApi');
    const userState = useSelector(selectUserState);
    const tokenData = getUserTokenData();
    const { token, expire_at } = tokenData || {};
    const isTokenExpired = getIsTokenExpired(expire_at);

    const skipQuery = isTokenExpired || !token || !userState.data || !queries.includes('data');
    const getPrizesQuery = useGetPrizesDataQuery(undefined, { skip: skipQuery });

    const [award, awardMutation] = useAwardPrizeMutation();
    const [cancel, cancelMutation] = useCancelPrizeMutation();

    const getPrizesErrors = useMemo(() => {
        return getRtkQueryErrors(getPrizesQuery.error, t.errors);
    }, [getPrizesQuery.error, t.errors]);
    const awardErrors = useMemo(() => {
        return getRtkQueryErrors(awardMutation.error, t.errors);
    }, [awardMutation.error, t.errors]);
    const cancelErrors = useMemo(() => {
        return getRtkQueryErrors(cancelMutation.error, t.errors);
    }, [cancelMutation.error, t.errors]);

    return {
        prizesData: {
            data: getPrizesQuery.data,
            isFetching: getPrizesQuery.isFetching,
            isLoading: getPrizesQuery.isLoading,
            isSuccess: getPrizesQuery.isSuccess,
            isError: getPrizesQuery.isError,
            errors: getPrizesErrors,
        },
        award: {
            fetch: award,
            data: awardMutation.data,
            isLoading: awardMutation.isLoading,
            isSuccess: awardMutation.isSuccess,
            isError: awardMutation.isError,
            errors: awardErrors,
        },
        cancel: {
            fetch: cancel,
            data: cancelMutation.data,
            isLoading: cancelMutation.isLoading,
            isSuccess: cancelMutation.isSuccess,
            isError: cancelMutation.isError,
            errors: cancelErrors,
        },
    };
};

function getIsTokenExpired(expireAt: number | undefined) {
    if (!expireAt) return false;
    return expireAt * 1000 < new Date().getTime();
}
