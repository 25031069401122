import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import {
    container,
    innerContainer,
    open,
    list,
    headerMobile,
    headerText,
    showAllButton,
    buttonMobile,
    filtersBox,
    buttons,
    clearButton,
    item,
} from './filters.module.scss';
import { noScroll } from '../../layouts/widget-layout.module.scss';

import FiltersIcon from '../../../assets/images/svg/filters.svg';
import BackIcon from '../../../assets/images/svg/back.svg';

import { IFilter, IFilters } from '../../models/filter.model';
import { getUrlParamValue } from '../../utils/get-url-param-value';
import { navigateThroughParams } from '../../utils/navigate-through-params';
import useTranslations from '../../hooks/use-translations';

import IconButton from '../atoms/icon-button';
import Button from '../atoms/button';
import Filter from '../molecules/filter';

interface IFiltersProps {
    className?: string;
    filters: IFilters;
    visibleOnStart?: number;
    handleClearFilters?: () => void;
}

const Filters: React.FC<IFiltersProps> = ({
    className = '',
    filters,
    visibleOnStart,
    handleClearFilters,
}) => {
    const visibleFilters = getVisibleFilters(filters);
    const [isLayerOpen, setLayerIsOpen] = useState(false);
    const t = useTranslations('Filters');
    const modalParam = getUrlParamValue('modal');

    const handleModal = () => {
        navigateThroughParams('modal', isLayerOpen, { truthy: 'closed', falsy: 'open' });
    };

    useEffect(() => {
        setLayerIsOpen(modalParam === 'open');
    }, [modalParam]);

    return (
        <div className={`${container} ${className} `}>
            {isLayerOpen && <Helmet bodyAttributes={{ class: noScroll }} />}
            <Button
                className={buttonMobile}
                onClick={handleModal}
                stylePreset="secondary"
                size="medium"
            >
                <FiltersIcon />
                {t.filters}
            </Button>
            <div className={`${filtersBox} ${isLayerOpen ? open : ''}`}>
                <div className={innerContainer}>
                    <div className={headerMobile}>
                        <IconButton onClick={handleModal} title={t.closeFilters}>
                            <BackIcon />
                        </IconButton>
                        <p className={headerText}>{t.header}</p>
                    </div>
                    <ul className={list}>
                        {visibleFilters.map((filter) => {
                            return (
                                <li key={`filter-item-${filter.paramName}`} className={item}>
                                    <Filter filter={filter} visibleOnStart={visibleOnStart} />
                                </li>
                            );
                        })}
                    </ul>
                    <div className={buttons}>
                        <Button
                            className={clearButton}
                            onClick={handleClearFilters}
                            stylePreset={'secondary'}
                        >
                            {t.clear}
                        </Button>
                        <Button className={showAllButton} onClick={handleModal}>
                            {t.showAll}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

function getVisibleFilters(filters: { [key: string]: IFilter }) {
    return Object.values(filters).filter((filter) => {
        return filter;
    });
}

export default Filters;
