// extracted by mini-css-extract-plugin
export var backgroundAnimation = "aes-summary-module--backgroundAnimation--00ec5";
export var big = "aes-summary-module--big--3c7e9";
export var block = "aes-summary-module--block--93a95";
export var box = "aes-summary-module--box--1102c";
export var container = "aes-summary-module--container--52bc9";
export var count = "aes-summary-module--count--bc8a7";
export var errorBlink = "aes-summary-module--error-blink--eb0b0";
export var filterTitle = "aes-summary-module--filter-title--d697a";
export var filterWrapper = "aes-summary-module--filter-wrapper--c90db";
export var grey = "aes-summary-module--grey--3d149";
export var header = "aes-summary-module--header--3ce23";
export var select = "aes-summary-module--select--1d272";
export var spacer = "aes-summary-module--spacer--f0db4";
export var text = "aes-summary-module--text--c3926";
export var title = "aes-summary-module--title--91717";