import React, { useEffect, useState } from 'react';

import { IFilter } from '../../models/filter.model';
import { IOption } from '../../models/option.model';

import Select from '../atoms/select';
import Input from '../atoms/input';

interface IFilterProps {
    className?: string;
    filter: IFilter;
    visibleOnStart?: number;
}

const Filter: React.FC<IFilterProps> = ({ className = '', filter, visibleOnStart = 7 }) => {
    const { label, paramName, usable, type } = filter;
    const [visibleOptions, setVisibleOptions] = useState(
        getVisibleAndEnabledOptions(true, visibleOnStart, filter)
    );

    useEffect(() => {
        setVisibleOptions(getVisibleAndEnabledOptions(true, visibleOnStart, filter));
    }, [filter, true, visibleOnStart]);

    return (
        <div className={className}>
            {type === 'text' && <Input name={paramName} placeholder={label} disabled={!usable} />}
            {type !== 'text' && (
                <Select
                    placeholder={label}
                    name={paramName}
                    options={visibleOptions}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={type === 'checkbox'}
                    closeMenuOnSelect={type !== 'checkbox'}
                    isDisabled={!usable}
                />
            )}
        </div>
    );
};

function getVisibleAndEnabledOptions(
    showAll: boolean,
    visibleOnStart: number,
    filter: IFilter
): IOption[] {
    const enabledOptions = filter.options.filter((option) => option.enabled);
    if (showAll || filter.type === 'radio-range') return enabledOptions;
    return enabledOptions.slice(0, visibleOnStart);
}

export default Filter;
