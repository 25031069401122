import React from 'react';

import { loaderWrapper, fullWidth, cover } from './loader.module.scss';

import Loading from '../../../assets/images/svg/loading-line.svg';

export interface ILoaderProps {
    className?: string;
    width?: 'full' | 'max-content' | 'cover';
}

const Loader: React.FC<ILoaderProps> = ({ className = '', width = 'max-content' }) => {
    return (
        <div
            className={[
                loaderWrapper,
                width === 'full' ? fullWidth : '',
                width === 'cover' ? cover : '',
                className,
            ].join(' ')}
        >
            <Loading />
        </div>
    );
};

export default Loader;
