import React from 'react';

import { container, title, button, logout } from './no-permission.module.scss';

import useTranslations from '../../hooks/use-translations';

import { useUser } from '../../hooks/use-user';

import Button from '../atoms/button';

interface INoPermissionProps {
    path: string;
}

const NoPermission: React.FC<INoPermissionProps> = () => {
    const t = useTranslations('NoPermission');
    const userState = useUser();

    const handleLogout = () => {
        try {
            userState.logout.fetch();
        } catch {}
    };

    return (
        <div className={container}>
            <p className={title}>{t.title}</p>
            <Button className={button} as={'link'} to={'/'}>
                {t.back}
            </Button>
            <Button
                className={`${button} ${logout}`}
                onClick={handleLogout}
                isLoading={userState.logout.isLoading}
            >
                {t.logout}
            </Button>
        </div>
    );
};

export default NoPermission;
