import { useEffect, useState } from 'react';

import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

export const useBackLink = (fallback?: string) => {
    const { language, defaultLanguage } = useI18next();

    const prefix = defaultLanguage === language ? '' : language;
    const defaultFallback = prefix ? `/${prefix}/` : '/';

    const [prevPathname, setPrevPathname] = useState(fallback || defaultFallback);

    useEffect(() => {
        const handleSessionStorage = () => {
            const newPrevPathname = getSessionStorageItem('prevPathname');
            if (!newPrevPathname) return;
            setPrevPathname(newPrevPathname);
        };
        handleSessionStorage();
        window.addEventListener('sessionStorage', handleSessionStorage);
        return () => {
            window.removeEventListener('sessionStorage', handleSessionStorage);
        };
    }, []);

    return prevPathname;
};

function getSessionStorageItem(key: string) {
    if (typeof sessionStorage === 'undefined') return null;
    return sessionStorage.getItem(key);
}
