// extracted by mini-css-extract-plugin
export var backButton = "aes-modal-module--back-button--b1f0c";
export var backgroundAnimation = "aes-modal-module--backgroundAnimation--612e7";
export var button = "aes-modal-module--button--0934a";
export var container = "aes-modal-module--container--776a6";
export var errorBlink = "aes-modal-module--error-blink--f4f18";
export var event = "aes-modal-module--event--e98c5";
export var eventStage = "aes-modal-module--event-stage--0aaab";
export var header = "aes-modal-module--header--d64cf";
export var icon = "aes-modal-module--icon--09d4b";
export var image = "aes-modal-module--image--fd6bf";
export var innerContainer = "aes-modal-module--inner-container--3210f";
export var label = "aes-modal-module--label--37ae5";
export var modalHeader = "aes-modal-module--modal-header--32053";
export var modalOpen = "aes-modal-module--modal-open--e4a77";
export var modalPrize = "aes-modal-module--modal-prize--45bca";
export var name = "aes-modal-module--name--18ffd";
export var place = "aes-modal-module--place--c6ab1";
export var prize = "aes-modal-module--prize--d10a4";
export var prizeContainer = "aes-modal-module--prize-container--b0f9a";
export var prizeData = "aes-modal-module--prize-data--c2969";
export var prizeImage = "aes-modal-module--prize-image--f37c7";
export var sent = "aes-modal-module--sent--58fc5";
export var sentContainer = "aes-modal-module--sent-container--bbd0e";
export var time = "aes-modal-module--time--a7c84";
export var value = "aes-modal-module--value--95938";