// extracted by mini-css-extract-plugin
export var backgroundAnimation = "aes-prize-module--backgroundAnimation--1cc72";
export var button = "aes-prize-module--button--a331c";
export var container = "aes-prize-module--container--9236a";
export var date = "aes-prize-module--date--0e892";
export var errorBlink = "aes-prize-module--error-blink--62649";
export var event = "aes-prize-module--event--d51fb";
export var eventStage = "aes-prize-module--event-stage--6d7b3";
export var header = "aes-prize-module--header--b6def";
export var image = "aes-prize-module--image--ab0e9";
export var label = "aes-prize-module--label--d0dec";
export var mobileHidden = "aes-prize-module--mobile-hidden--85c23";
export var name = "aes-prize-module--name--7d395";
export var prizeContainer = "aes-prize-module--prize-container--8e6c0";
export var prizeData = "aes-prize-module--prize-data--aabc6";
export var prizeImage = "aes-prize-module--prize-image--7249e";
export var prizeWrapper = "aes-prize-module--prize-wrapper--3bb85";
export var sent = "aes-prize-module--sent--27764";
export var time = "aes-prize-module--time--c4ecc";
export var value = "aes-prize-module--value--2e762";