import React from 'react';

import { container, button, isSelected } from './switch.module.scss';
import { useField } from 'formik';
import { IFilter } from '../../models/filter.model';

interface ISwitchProps {
    className?: string;
    filter?: IFilter;
}

const Switch: React.FC<ISwitchProps> = ({ className = '', filter }) => {
    const [, meta, helpers] = useField(filter ? filter.paramName : '');

    const handleClick = (value: string) => {
        helpers.setValue(value);
    };

    filter?.options.sort((a, b) => {
        if (a.value > b.value) {
            return -1;
        }
        if (a.value < b.value) {
            return 1;
        }
        return 0;
    });

    return (
        <div className={`${container} ${className}`}>
            {filter &&
                filter.options.map((option) => {
                    return (
                        <button
                            key={`switch-${option.value}`}
                            className={`${button} ${meta.value === option.value ? isSelected : ''}`}
                            onClick={() => {
                                handleClick(option.value);
                            }}
                        >
                            {option.label}
                        </button>
                    );
                })}
        </div>
    );
};

export default Switch;
