import React from 'react';
import { useFormikContext } from 'formik';

import { container, list, buttonClear } from './selected-filters.module.scss';

import { ISelectedFilter } from '../../models/filter.model';
import useTranslations from '../../hooks/use-translations';

import FilterTag, { IFilterTagProps } from '../atoms/filter-tag';
import Button from '../atoms/button';

interface ISelectedFiltersProps {
    className?: string;
    selectedFilters: ISelectedFilter[];
}

const SelectedFilters: React.FC<ISelectedFiltersProps> = ({ className = '', selectedFilters }) => {
    const t = useTranslations('SelectedFilters');
    const { setFieldValue, values, setValues } =
        useFormikContext<Record<string, string | string[]>>();

    const handleDelete: IFilterTagProps['onDelete'] = (filter) => {
        setFieldValue(filter.paramName, getNewFieldValue(filter, values));
    };

    const handleDeleteAll = () => {
        const newValues: Record<string, string | string[]> = {};
        const clearedFilters: string[] = [];
        selectedFilters.forEach((filter) => {
            if (clearedFilters.includes(filter.paramName)) return;
            if (Array.isArray(values[filter.paramName])) {
                newValues[filter.paramName] = [];
            }
            newValues[filter.paramName] = '';
            clearedFilters.push(filter.paramName);
        });
        setValues({ ...values, ...newValues });
    };

    return (
        <div className={`${container} ${className}`}>
            <ul className={list}>
                {selectedFilters.map((filter) => {
                    return (
                        <li key={`selected-filter-${filter.paramName}-${filter.option.value}`}>
                            <FilterTag filter={filter} onDelete={handleDelete} />
                        </li>
                    );
                })}
            </ul>
            {selectedFilters.length > 0 && (
                <Button
                    className={buttonClear}
                    onClick={handleDeleteAll}
                    stylePreset="primary-outline"
                >
                    {t.clear}
                </Button>
            )}
        </div>
    );
};

function getNewFieldValue(filter: ISelectedFilter, values: Record<string, string | string[]>) {
    const value = values[filter.paramName];
    if (!Array.isArray(value)) return '';
    if (filter.filterType === 'radio-range') {
        const newRangeValue = value.map((valueItem, index) => {
            const isEdgeToDelete = ['min', 'max'][index] === filter.rangeEdge;
            return isEdgeToDelete ? '' : valueItem;
        });
        const isRangeEmpty = newRangeValue.filter((value) => !!value).length === 0;
        return isRangeEmpty ? '' : newRangeValue;
    }
    return value.filter((valueItem) => valueItem !== filter.option.value);
}

export default SelectedFilters;
