import React from 'react';

import {
    container,
    block,
    title,
    box,
    text,
    big,
    grey,
    count,
    spacer,
    header,
    filterWrapper,
    filterTitle,
    select,
} from './aes-summary.module.scss';

import useTranslations from '../../hooks/use-translations';
import { IFilter } from '../../models/filter.model';
import { IPrizesData } from '../../models/prize.model';
import { useUser } from '../../hooks/use-user';

import Select from '../atoms/select';

interface IAesSummaryProps {
    filter?: IFilter;
    prizesData?: IPrizesData;
}

const AesSummary: React.FC<IAesSummaryProps> = ({ filter, prizesData }) => {
    const t = useTranslations('AesSummary');
    const delivered = prizesData?.delivered || 0;
    const undelivered = prizesData?.undelivered || 0;
    const sum = delivered + undelivered;
    const { data } = useUser();
    const isFilterVisible = data && data.permissions?.includes('Area');

    return (
        <div className={container}>
            <div className={header}>
                <h1 className={title}>{t.title}</h1>
                {filter && filter.usable && isFilterVisible && (
                    <div className={filterWrapper}>
                        <span className={filterTitle}>{t.person}</span>
                        <Select
                            name={filter.paramName}
                            options={filter.options}
                            placeholder={t.all}
                            className={select}
                        />
                    </div>
                )}
            </div>

            <div className={box}>
                <div className={block}>
                    <p className={`${text} ${big}`}>{t.prizes}</p>
                    <p className={`${text} ${grey}`}>
                        {t.sum} {sum}
                    </p>
                </div>
                <span className={spacer} />
                <div className={block}>
                    <p className={`${count} ${big}`}>{undelivered}</p>
                    <p className={`${text}`}>{t.toSend}</p>
                </div>
                <span className={spacer} />
                <div className={block}>
                    <p className={`${count} ${grey}`}>{delivered}</p>
                    <p className={`${text} ${grey}`}>{t.sent}</p>
                </div>
            </div>
        </div>
    );
};

export default AesSummary;
