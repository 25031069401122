import React, { useState } from 'react';
import { Link } from '@reach/router';

import {
    container,
    listContainer,
    list,
    header,
    info,
    label,
    value,
    right,
    quantity,
    sent,
    button,
    isOpen,
    item,
    icon,
} from './aes-card.module.scss';

import ArrowIcon from '../../../assets/images/svg/chevron-right.svg';
import PointIcon from '../../../assets/images/svg/point.svg';

import { ICity } from '../../models/prize.model';
import { getUrlParamValue } from '../../utils/get-url-param-value';
import useTranslations from '../../hooks/use-translations';
import AnimateHeight from 'react-animate-height';

interface IAesCardProps {
    card: ICity;
    isSent: boolean;
}

const AesCard: React.FC<IAesCardProps> = ({ card, isSent }) => {
    const t = useTranslations('AesCard');

    const [isCardOpen, setIsCardOpen] = useState(false);
    const [height, setHeight] = useState<number | 'auto'>(0);
    const deliveryParam = getUrlParamValue('delivery');

    const handleCardClick = () => {
        setHeight(height === 0 ? 'auto' : 0);
        setIsCardOpen(!isCardOpen);
    };

    return (
        <div className={`${container} ${isCardOpen ? isOpen : ''}`}>
            <button type="button" className={header} onClick={handleCardClick}>
                <div className={info}>
                    <p className={label}>{card.name}</p>
                    <p className={value}>
                        {t.shops} {card.shops.length}
                    </p>
                </div>
                <div className={right}>
                    <p className={`${quantity} ${isSent ? sent : ''} quantity`}>
                        {isSent ? t.sent : t.toSend}
                        <span>
                            {getPrizesSum(card)} {t.unit}
                        </span>
                    </p>
                    <div className={button}>
                        <ArrowIcon />
                    </div>
                </div>
            </button>
            <div className={listContainer}>
                <AnimateHeight className={list} height={height} duration={300}>
                    {card.shops.map((shop) => {
                        return (
                            <Link
                                className={item}
                                to={`shop?shopId=${shop.shopId}&code=${shop.code}&delivery=${
                                    deliveryParam ? deliveryParam : 'undelivered'
                                }`}
                                key={`shop-${shop.shopId}`}
                            >
                                <div className={icon}>
                                    <PointIcon />
                                </div>
                                <div className={info}>
                                    <p className={label}>{shop.code}</p>
                                    <p className={value}>
                                        {shop.city} {shop.street} {shop.houseNumber}
                                    </p>
                                </div>
                                <p className={`${quantity} ${isSent ? sent : ''}`}>
                                    <span>
                                        {shop.prizes} {t.unit}
                                    </span>
                                </p>
                            </Link>
                        );
                    })}
                </AnimateHeight>
            </div>
        </div>
    );
};

function getPrizesSum(card: ICity) {
    let sum = 0;

    card.shops.forEach((shop) => {
        sum += shop.prizes;
    });

    return sum;
}

export default AesCard;
