import React, { useEffect, useRef, useState } from 'react';
import { Field, useField } from 'formik';

import {
    container,
    button,
    dropdown,
    input,
    optionBox,
    list,
    text,
    icon,
} from './list-sort.module.scss';

import ArrowIcon from '../../../assets/images/svg/chevron-right.svg';

import { IFilter } from '../../models/filter.model';
import useTranslations from '../../hooks/use-translations';

import Dropdown from '../hoc/dropdown';

interface IListSortProps {
    className?: string;
    sortFilter?: IFilter;
    isOpen?: boolean;
}

const ListSort: React.FC<IListSortProps> = ({ className = '', sortFilter, isOpen = false }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [, meta] = useField(sortFilter ? sortFilter.paramName : '');
    const buttonRef = useRef(null);
    const t = useTranslations('ListSort');
    const handleCloseDropdown = () => {
        setIsDropdownOpen(false);
    };

    const handleToggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    useEffect(() => {
        setIsDropdownOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [meta.value]);

    return (
        <div className={`${container} ${className}`}>
            <div ref={buttonRef}>
                <button className={button} onClick={handleToggleDropdown}>
                    <div className={icon}>
                        <ArrowIcon />
                        <ArrowIcon />
                    </div>
                    {t.sort}
                    <span>
                        {(sortFilter &&
                            sortFilter.options.find((option) => option.value === meta.value)
                                ?.label) ||
                            ''}
                    </span>
                </button>
            </div>
            <Dropdown
                className={dropdown}
                isOpen={isDropdownOpen}
                closeFunction={handleCloseDropdown}
                buttonRef={buttonRef}
            >
                <ul className={list}>
                    {sortFilter &&
                        sortFilter.options.map((option) => {
                            return (
                                <li key={`sort-item-${option.value}`}>
                                    <label className={optionBox}>
                                        <Field
                                            className={input}
                                            name={sortFilter.paramName}
                                            value={option.value}
                                            type="radio"
                                        />
                                        <span className={text}>{option.label}</span>
                                    </label>
                                </li>
                            );
                        })}
                </ul>
            </Dropdown>
        </div>
    );
};

export default ListSort;
