import React, { useState } from 'react';
import { BaseImage } from '@alterpage/gatsby-plugin-image';

import {
    container,
    header,
    name,
    time,
    sent,
    event,
    image,
    value,
    eventStage,
    prizeWrapper,
    label,
    prizeContainer,
    prizeImage,
    prizeData,
    date,
    button,
    mobileHidden,
} from './aes-prize.module.scss';

import { IShopDetails } from '../../models/prize.model';
import { navigateThroughParams } from '../../utils/navigate-through-params';
import { breakpoints } from '../../config/breakpoints';
import { useModal } from '../../hooks/use-modal';
import { getDateFromUnixTimestamp } from '../../utils/get-date-from-unix-timestamp';
import useTranslations from '../../hooks/use-translations';
import useMediaQuery from '../../hooks/use-media-query';

import Button from '../atoms/button';
import AesModal from './aes-modal';

interface IAesPrizeProps {
    data: IShopDetails;
    handleRefetch: () => void;
}

const AesPrize: React.FC<IAesPrizeProps> = ({ data, handleRefetch }) => {
    const t = useTranslations('AesPrize');
    const isMobile = useMediaQuery(breakpoints.iPad);
    const { addModal } = useModal();
    const [isOpen, setIsOpen] = useState(false);

    const handleModal = () => {
        if (isMobile) {
            handleNavigate(true);
        } else {
            addModal({
                modalKey: 'aes-prize-modal',
                modalProps: {
                    actions: ['close'],
                    data: data,
                    isOpen: true,
                    handleRefetch: handleRefetch,
                },
            });
        }
    };

    const handleNavigate = (isOpen: boolean) => {
        setIsOpen(isOpen);
        navigateThroughParams('modal', isOpen, { truthy: 'closed', falsy: 'open' });
    };

    return (
        <div className={container}>
            <div className={header}>
                <p className={name}>
                    {data.firstname} {data.lastname}
                </p>
                {data.prizeDeliveredAt ? (
                    <p className={`${time} ${sent}`}>
                        <span>{t.sent}</span> {getDateFromUnixTimestamp(data.prizeDeliveredAt)}
                    </p>
                ) : (
                    <p className={time}>
                        <span>{t.waiting}</span> {data.waitingDays} {t.days}
                    </p>
                )}
            </div>
            <div className={event}>
                <div className={image}>
                    <BaseImage
                        image={undefined}
                        url={data.contest.imageUrl}
                        alt={data.contest.name}
                    />
                </div>
                <div>
                    <p className={value}>{data.contest.name}</p>
                    <p className={eventStage}>
                        {t.stage} {data.contest.stage}
                    </p>
                </div>
            </div>
            <div className={prizeWrapper}>
                <div>
                    <p className={label}>{t.prize}</p>
                    <div className={prizeContainer}>
                        <div className={prizeImage}>
                            <BaseImage
                                image={undefined}
                                url={data.prize.imageUrl}
                                alt={data.prize.name}
                            />
                        </div>
                        <div className={prizeData}>
                            <p className={value}>{data.prize.name}</p>
                            {data.prize.parameters.length > 0 &&
                                data.prize.parameters.map((param) => {
                                    return (
                                        <p>
                                            {param.paramName + ' '} {param.paramValue}
                                        </p>
                                    );
                                })}
                        </div>
                    </div>
                </div>
                <div className={date}>
                    <p className={label}>{t.date}</p>
                    <p className={value}>{getDateFromUnixTimestamp(data.prizeAwardedAt)}</p>
                </div>
            </div>
            <Button className={button} stylePreset={'secondary'} onClick={handleModal}>
                {data.prizeDeliveredAt ? t.cancel : t.send}
            </Button>
            <AesModal
                data={data}
                className={mobileHidden}
                onBack={handleNavigate}
                handleRefetch={handleRefetch}
                isOpen={isOpen}
            />
        </div>
    );
};

export default AesPrize;
