import React, { CSSProperties } from 'react';
import { GatsbyImageProps, IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';

import { imgBox, ratioContainer, imgInnerBox, img, imgNoRatio } from './base-image.module.scss';

export interface IBaseImageProps extends Omit<GatsbyImageProps, 'image' | 'alt' | 'className'> {
    className?: string;
    image: IGatsbyImageData | undefined;
    url?: string;
    alt?: string;
    caption?: string;
    aspectRatio?: CSSProperties['aspectRatio'] | number;
    ratioClass?: string;
    preserveSpace?: boolean;
    showCaption?: boolean;
    renderCaption?: (caption: string) => React.ReactNode;
}

export const BaseImage: React.FC<IBaseImageProps> = ({
    className = '',
    aspectRatio,
    ratioClass,
    image,
    url,
    alt = '',
    caption = '',
    preserveSpace = true,
    objectPosition = 'center center',
    objectFit = 'cover',
    loading = 'lazy',
    imgClassName = '',
    imgStyle,
    style,
    renderCaption,
    showCaption = false,
    ...rest
}) => {
    const hasRatio = !!ratioClass || !!aspectRatio;
    const inlineRatio = aspectRatio && !ratioClass ? { aspectRatio: aspectRatio.toString() } : {};

    if (!preserveSpace && !image && !url) return null;

    return (
        <figure className={`${className} image ${hasRatio ? `image--with-ratio` : ''}`}>
            <div className={`${ratioContainer} ${ratioClass} image__ratio`} style={inlineRatio}>
                <div className={`${hasRatio ? imgBox : ''} image__box`}>
                    {image && (
                        <GatsbyImage
                            className={`${hasRatio ? imgInnerBox : ''} image__inner-box`}
                            image={image}
                            alt={alt || ''}
                            imgClassName={`${imgClassName} image__img`}
                            imgStyle={imgStyle}
                            loading={loading}
                            style={style}
                            {...(hasRatio
                                ? {
                                      objectPosition,
                                      objectFit,
                                  }
                                : {})}
                            {...rest}
                        />
                    )}
                    {!image && url && (
                        <div
                            className={`${hasRatio ? imgInnerBox : ''} image__inner-box`}
                            style={style}
                        >
                            <img
                                className={`${
                                    hasRatio ? img : imgNoRatio
                                } ${imgClassName} image__img`}
                                src={url}
                                alt={alt || ''}
                                loading={loading}
                                style={{
                                    ...(hasRatio
                                        ? {
                                              objectPosition,
                                              objectFit,
                                          }
                                        : {}),
                                    ...imgStyle,
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            {caption &&
                showCaption &&
                (typeof renderCaption === 'function' ? (
                    renderCaption(caption)
                ) : (
                    <figcaption className="image__caption">{caption}</figcaption>
                ))}
        </figure>
    );
};
