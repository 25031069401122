import React from 'react';
import { Link } from '@reach/router';
import { Formik } from 'formik';

import {
    container,
    header,
    list,
    loading,
    listHeader,
    loader,
    empty,
} from './shop-details.module.scss';

import BackIcon from '../../../assets/images/svg/back.svg';

import { IShopDetails } from '../../models/prize.model';
import pagesContext from '../../../config/pages-context';
import { endpoints } from '../../config/endpoints';
import { useList } from '../../hooks/use-list';
import { getUrlParamValue } from '../../utils/get-url-param-value';
import { useBackLink } from '../../hooks/use-back-link';
import useTranslations from '../../hooks/use-translations';

import AesPrize from '../molecules/aes-prize';
import Switch from '../atoms/switch';
import HandleFormikChange from '../hoc/handle-formik-change';
import Pagination from '../molecules/pagination';
import Loader from '../atoms/loader';

interface IShopDetailsProps {
    path: string;
}

const ShopDetails: React.FC<IShopDetailsProps> = () => {
    const t = useTranslations('ShopDetails');
    const prevPathname = useBackLink(`/${pagesContext.aes.slug}`);
    const shopIdParam = getUrlParamValue('shopId') || '';
    const shopCodeParam = getUrlParamValue('code') || '';
    const deliveryParam = getUrlParamValue('delivery') || '';

    const { items, filters, status, paginationPaths, handleChange, isInitialLoading, refetchList } =
        useList<IShopDetails>({
            endpoint: `${endpoints.aes.shopApplications}?shopId=${shopIdParam}`,
            paramsWhiteList: ['shopId', 'code'],
            ignoredParams: ['modal', 'no-scroll'],
        });

    const deliveryFilter = filters ? filters.delivery : undefined;

    const handleRefetch = () => {
        refetchList();
    };

    return (
        <div className={container}>
            {status === 'loading' && isInitialLoading && <Loader className={loader} />}
            {items && !isInitialLoading && (
                <>
                    <div className={header}>
                        <Link to={prevPathname}>
                            <BackIcon />
                        </Link>
                        {t.title} {shopCodeParam}
                    </div>
                    <Formik
                        initialValues={{
                            delivery: `${deliveryParam ? deliveryParam : 'undelivered'}`,
                        }}
                        onSubmit={() => {}}
                    >
                        {({ values }) => (
                            <>
                                <HandleFormikChange onChange={handleChange} />
                                <Switch filter={deliveryFilter} />
                                <div className={listHeader}>
                                    <p>{t.name}</p>
                                    <p>
                                        {values.delivery === 'delivered' ? t.timeDelivered : t.time}
                                    </p>
                                    <p>{t.contest}</p>
                                    <p>{t.prize}</p>
                                    <p>{t.date}</p>
                                </div>
                                <div className={`${list} ${status === 'loading' ? loading : ''}`}>
                                    {items &&
                                        items.length > 0 &&
                                        items.map((item) => {
                                            return (
                                                <AesPrize
                                                    key={`application-${item.applicationId}`}
                                                    data={item}
                                                    handleRefetch={handleRefetch}
                                                />
                                            );
                                        })}
                                    {items && items.length === 0 && (
                                        <p className={empty}>
                                            {values.delivery === 'delivered'
                                                ? t.emptyDelivered
                                                : t.emptyUndelivered}
                                        </p>
                                    )}
                                </div>
                                <Pagination paginationPaths={paginationPaths} />
                            </>
                        )}
                    </Formik>
                </>
            )}
        </div>
    );
};

export default ShopDetails;
