export function getDateFromUnixTimestamp(
    unixTimestamp: number,
    separator: 'dot' | 'dash' | 'slash' | 'dash-reverse' = 'dot',
    includeTime = false
): string {
    const timestamp = unixTimestamp * 1000;
    const date = new Date(timestamp);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const time = includeTime ? hours + ':' + minutes + ':' + seconds : '';

    switch (separator) {
        case 'dot':
            return `${day}.${month}.${year} ${time}`;
        case 'dash':
            return `${day}-${month}-${year} ${time}`;
        case 'dash-reverse':
            return `${year}-${month}-${day} ${time}`;
        case 'slash':
            return `${day}/${month}/${year} ${time}`;
    }
}
