import React from 'react';
import { graphql } from 'gatsby';
import { Router } from '@reach/router';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, column } from './aes.module.scss';

import { IPage } from '../models/page.model';

import MainLayout from '../layouts/main-layout';
import App from '../aes/components/sections/aes';

interface IAesPageProps {
    readonly data: {
        page: IPage | null;
    };
}

const Aes: React.FC<IAesPageProps> = ({ data }) => {
    const { page } = data;
    const { t } = useI18next();

    return (
        <MainLayout
            page={page}
            footerType={'simple'}
            className={container}
            headerTitle={t('aes.panel')}
        >
            <Router className={column}>
                <App path="aes/*" />
            </Router>
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default Aes;
