import React, { useEffect, useState } from 'react';
import { BaseImage } from '@alterpage/gatsby-plugin-image';

import {
    container,
    modalOpen,
    innerContainer,
    header,
    name,
    time,
    sent,
    event,
    image,
    value,
    eventStage,
    prize,
    label,
    prizeContainer,
    prizeImage,
    prizeData,
    button,
    modalHeader,
    place,
    icon,
    modalPrize,
    backButton,
    sentContainer,
} from './aes-modal.module.scss';

import BackIcon from '../../../assets/images/svg/back.svg';
import PointIcon from '../../../assets/images/svg/point.svg';

import { IShopDetails } from '../../models/prize.model';
import { usePrize } from '../../hooks/use-prize';
import { getDateFromUnixTimestamp } from '../../utils/get-date-from-unix-timestamp';
import { useModal } from '../../hooks/use-modal';
import useTranslations from '../../hooks/use-translations';

import Button from '../atoms/button';

interface IAesModalProps {
    data: IShopDetails;
    isOpen: boolean;
    className?: string;
    handleRefetch?: () => void;
    modalId?: number;
    onBack?: (isOpen: boolean) => void;
}

const AesModal: React.FC<IAesModalProps> = ({
    data,
    isOpen,
    className = '',
    handleRefetch,
    modalId,
    onBack,
}) => {
    const t = useTranslations('AesModal');
    const { cancel, award } = usePrize();
    const [isSent, setIsSent] = useState(false);
    const { removeModal } = useModal();

    const handleSubmit = () => {
        if (!!data.prizeDeliveredAt) {
            cancel.fetch({ applicationId: data.applicationId });
        } else {
            award.fetch({ applicationId: data.applicationId });
        }
    };

    const handleClose = () => {
        if (onBack) {
            onBack(false);
        }

        if (modalId) {
            removeModal(modalId);
        }

        if ((cancel.isSuccess || award.isSuccess) && handleRefetch) {
            handleRefetch();

            setTimeout(() => {
                setIsSent(false);
            }, 500);
        }
    };

    useEffect(() => {
        if (cancel.isSuccess || award.isSuccess) {
            setIsSent(true);
        }
    }, [cancel.isSuccess, award.isSuccess]);

    return (
        <div
            className={`${className} ${container} ${isOpen ? modalOpen : ''} ${isSent ? sent : ''}`}
        >
            <div className={innerContainer}>
                {isSent && (
                    <div className={sentContainer}>
                        <p>{data.prizeDeliveredAt ? t.modalHeaderSentCancel : t.modalHeaderSent}</p>
                        <Button onClick={handleClose}>{t.back}</Button>
                    </div>
                )}
                {!isSent && (
                    <>
                        <div className={modalHeader}>
                            <button className={backButton} type={'button'} onClick={handleClose}>
                                <BackIcon />
                            </button>
                            {data.prizeDeliveredAt ? t.modalHeaderCancel : t.modalHeader}
                        </div>
                        <div className={header}>
                            <p className={name}>
                                {data.firstname} {data.lastname}
                            </p>
                            {data.prizeDeliveredAt ? (
                                <p className={`${time} ${sent}`}>
                                    <span>{t.sent}</span>{' '}
                                    {getDateFromUnixTimestamp(data.prizeDeliveredAt)}
                                </p>
                            ) : (
                                <p className={time}>
                                    <span>{t.waiting}</span> {data.waitingDays} {t.days}
                                </p>
                            )}
                        </div>
                        <div className={event}>
                            <div className={image}>
                                <BaseImage
                                    image={undefined}
                                    url={data.contest.imageUrl}
                                    alt={data.contest.name}
                                />
                            </div>
                            <div>
                                <p className={value}>{data.contest.name}</p>
                                <p className={eventStage}>
                                    {t.stage} {data.contest.stage}
                                </p>
                            </div>
                        </div>
                        <div className={place}>
                            <div className={icon}>
                                <PointIcon />
                            </div>
                            <div>
                                <p className={value}>{data.city}</p>
                                <p className={eventStage}>{data.street}</p>
                            </div>
                        </div>
                        <div className={`${prize} ${modalPrize}`}>
                            <p className={label}>{t.prize}</p>
                            <div className={prizeContainer}>
                                <div className={prizeImage}>
                                    <BaseImage
                                        image={undefined}
                                        url={data.prize.imageUrl}
                                        alt={data.prize.name}
                                    />
                                </div>
                                <div className={prizeData}>
                                    <p className={value}>{data.prize.name}</p>
                                    {data.prize.parameters.length > 0 &&
                                        data.prize.parameters.map((param) => {
                                            return (
                                                <p>
                                                    {param.paramName + ' '}
                                                    {param.paramValue}
                                                </p>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                        <Button
                            className={button}
                            stylePreset={'primary'}
                            onClick={handleSubmit}
                            isLoading={cancel.isLoading || award.isLoading}
                        >
                            {data.prizeDeliveredAt ? t.cancelConfirm : t.send}
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default AesModal;
