import React, { forwardRef } from 'react';

import { button, badge } from './icon-button.module.scss';

interface IIconButtonProps {
    className?: string;
    title?: string;
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    badgeNumber?: number;
    innerRef?: React.Ref<any>;
    onMouseDown?: () => void;
    onMouseUp?: () => void;
    isTargetable?: boolean;
    type?: React.ComponentProps<'button'>['type'];
}

const IconButton = forwardRef<HTMLButtonElement, IIconButtonProps>(
    (
        {
            className = '',
            title,
            children,
            onClick,
            badgeNumber = 0,
            onMouseDown,
            onMouseUp,
            isTargetable = true,
            type,
        },
        ref
    ) => {
        return (
            <button
                className={`${button} ${className}`}
                title={title}
                aria-label={title}
                onClick={onClick}
                ref={ref}
                type={type}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onTouchStart={onMouseDown}
                onTouchEnd={onMouseUp}
                tabIndex={isTargetable ? 0 : -1}
            >
                {badgeNumber > 0 && (
                    <div className={badge}>{badgeNumber > 99 ? 99 : badgeNumber}</div>
                )}
                {children}
            </button>
        );
    }
);

export default IconButton;
