import React, { Fragment } from 'react';
import { Link } from 'gatsby';

import { container, pageLink, active, disabled } from './pagination.module.scss';

import ArrowLeft from '../../../assets/images/svg/arrow-left.svg';
import ArrowRight from '../../../assets/images/svg/arrow-right.svg';

export interface IPaginationProps {
    className?: string;
    paginationPaths: string[];
    range?: number;
}

const Pagination: React.FC<IPaginationProps> = ({ className = '', paginationPaths, range = 2 }) => {
    const pagePath = getPagePath() || '';
    const activeIndex = paginationPaths.indexOf(pagePath);
    const isMin = activeIndex === 0;
    const isMax = activeIndex === paginationPaths.length - 1;

    if (paginationPaths.length <= 1) {
        return null;
    }

    return (
        <div aria-label="pagination navigation" className={`${container} ${className}`}>
            <Link
                to={paginationPaths[activeIndex - 1]}
                className={`${pageLink} ${isMin ? disabled : ''}`}
            >
                <ArrowLeft />
            </Link>
            {paginationPaths.map((path, index) => {
                const isVisible = getVisibility({
                    index: index,
                    activeIndex: activeIndex,
                    paginationLength: paginationPaths.length,
                    range: range,
                });
                const isActive = paginationPaths.indexOf(path) === activeIndex;
                return (
                    <Fragment key={`pagination-fragment-${index}`}>
                        {index === paginationPaths.length - 1 &&
                            activeIndex < paginationPaths.length - range - 2 && (
                                <Link
                                    key="pagination-link-after"
                                    to={getMiddlePath({
                                        paginationPaths,
                                        activeIndex,
                                        range,
                                        isBefore: false,
                                    })}
                                    className={pageLink}
                                >
                                    ...
                                </Link>
                            )}
                        {isVisible && (
                            <Link
                                key={`pagination-link-${index}`}
                                to={path}
                                className={`${pageLink} ${isActive ? active : ''}`}
                            >
                                {index + 1}
                            </Link>
                        )}
                        {index === 0 && activeIndex > range + 1 && (
                            <Link
                                key="pagination-link-before"
                                to={getMiddlePath({
                                    paginationPaths,
                                    activeIndex,
                                    range,
                                    isBefore: true,
                                })}
                                className={pageLink}
                            >
                                ...
                            </Link>
                        )}
                    </Fragment>
                );
            })}
            <Link
                to={paginationPaths[activeIndex + 1]}
                className={`${pageLink} ${isMax ? disabled : ''}`}
            >
                <ArrowRight />
            </Link>
        </div>
    );
};

function getPagePath() {
    if (typeof window === 'undefined') return;
    return `${window.location.pathname}${window.location.search}`;
}

interface IGetVisibilityConfig {
    index: number;
    activeIndex: number;
    paginationLength: number;
    range: number;
}

function getVisibility({
    index,
    activeIndex,
    paginationLength,
    range,
}: IGetVisibilityConfig): boolean {
    if (index === 0) return true;
    if (activeIndex === index) return true;
    if (activeIndex - range <= index && activeIndex + range >= index) return true;
    return paginationLength - 1 === index;
}

interface getMiddleKeyConfig {
    paginationPaths: string[];
    activeIndex: number;
    range: number;
    isBefore: boolean;
}

function getMiddlePath({ paginationPaths, activeIndex, range, isBefore }: getMiddleKeyConfig) {
    let hiddenPaths;
    if (isBefore) {
        hiddenPaths = paginationPaths.slice(1, activeIndex - range);
    } else {
        hiddenPaths = paginationPaths.slice(activeIndex + range + 1, paginationPaths.length - 1);
    }
    return hiddenPaths[Math.floor(hiddenPaths.length / 2)];
}

export default Pagination;
