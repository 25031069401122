export const endpoints = {
    features: '/features',
    settings: {
        all: '/settings',
        theme: '/settings/theme',
        shop: '/settings/shop',
        user: '/settings/user',
    },
    aes: {
        cities: '/aes/cities',
        delivered: '/aes/prize-mark-as-delivered',
        undelivered: '/aes/prize-mark-as-undelivered',
        prizes: '/aes/prizes-data',
        shopApplications: '/aes/shop-applications',
    },
};
