import React, { RefObject, useRef } from 'react';

import { container, open } from './dropdown.module.scss';

import { useCloseOnOutsideClick } from '../../hooks/use-close-on-outside-click';

interface IDropdownProps {
    className?: string;
    isOpen: boolean;
    buttonRef?: RefObject<HTMLElement>;
    closeFunction(): void;
    children?: React.ReactNode;
}

const Dropdown: React.FC<IDropdownProps> = ({
    className = '',
    isOpen = false,
    closeFunction = () => {},
    buttonRef,
    children,
}) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    useCloseOnOutsideClick(dropdownRef, buttonRef, () => closeFunction());

    return (
        <div className={`${container} ${className} ${isOpen ? open : ''}`} ref={dropdownRef}>
            {children}
        </div>
    );
};

export default Dropdown;
