import React, { useEffect } from 'react';

export function useCloseOnOutsideClick(
    dropdownRef: React.RefObject<HTMLDivElement> | undefined,
    buttonRef: React.RefObject<HTMLElement> | undefined,
    closeFunction: () => void
) {
    useEffect(() => {
        if (!buttonRef || !dropdownRef) return;
        const dropdown = dropdownRef.current;
        if (!dropdown) return;

        const handleClose = (event: MouseEvent): void => {
            const target = event.target as Node | null;
            if (!dropdown) return;
            if (buttonRef?.current && buttonRef.current.contains(target)) return;
            if (dropdown.contains(target)) return;
            closeFunction();
        };

        document.addEventListener('click', handleClose, true);

        return () => {
            document.removeEventListener('click', handleClose, true);
        };
    }, [buttonRef, closeFunction]);
}
