import React, { useEffect } from 'react';
import { Router } from '@reach/router';

import { loader } from '../../../templates/aes.module.scss';

import { useUser } from '../../hooks/use-user';
import { navigate } from 'gatsby';
import pagesContext from '../../../config/pages-context';

import Loader from '../atoms/loader';
import ShopListing from './shop-listing';
import ShopDetails from './shop-details';
import NoPermission from './no-permission';

interface IAesProps {
    path: string;
}

const Aes: React.FC<IAesProps> = () => {
    const { data, isLoggedIn } = useUser();
    const hasPermission =
        data && (data.permissions?.includes('Ph') || data.permissions?.includes('Area'));

    useEffect(() => {
        if (!isLoggedIn) {
            navigate(`/${pagesContext.logIn.slug}`);
        }
    }, [isLoggedIn]);

    if (!data) return <Loader className={loader} />;

    return (
        <Router>
            {hasPermission ? (
                <>
                    <ShopListing path="/"></ShopListing>
                    <ShopDetails path="shop" />
                </>
            ) : (
                <NoPermission path={'/'} />
            )}
        </Router>
    );
};

export default Aes;
