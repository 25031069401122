import alterpressApi from './base';
import { endpoints } from '../../config/endpoints';
import { IPrizesData } from '../../models/prize.model';

export const prizeApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['Prize'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getPrizesData: builder.query<IPrizesData, undefined>({
                query: () => `${endpoints.aes.prizes}`,
            }),
            awardPrize: builder.mutation<void, { applicationId: number }>({
                query: ({ applicationId }) => ({
                    method: 'POST',
                    url: `${endpoints.aes.delivered}/${applicationId}`,
                }),
            }),
            cancelPrize: builder.mutation<void, { applicationId: number }>({
                query: ({ applicationId }) => ({
                    method: 'POST',
                    url: `${endpoints.aes.undelivered}/${applicationId}`,
                }),
            }),
        }),
    });

export const { useGetPrizesDataQuery, useAwardPrizeMutation, useCancelPrizeMutation } = prizeApi;
