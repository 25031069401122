import { navigate } from 'gatsby';

export const navigateThroughParams = (
    key: string,
    condition: boolean,
    values: { truthy: string; falsy: string }
) => {
    if (typeof window !== 'undefined' && 'URLSearchParams' in window) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(`${key}`, condition ? `${values.truthy}` : `${values.falsy}`);
        searchParams.set(`no-scroll`, 'true');
        const path = window.location.pathname + '?' + searchParams.toString();
        navigate(path);
    }
};
