import React from 'react';

import { container, content, optionLabel, button, buttonIcon } from './filter-tag.module.scss';

import CloseIcon from '../../../assets/images/svg/close.svg';

import { ISelectedFilter } from '../../models/filter.model';

import useTranslations from '../../hooks/use-translations';

export interface IFilterTagProps {
    className?: string;
    filter: ISelectedFilter;
    onDelete?(selectedFilter: ISelectedFilter): void;
}

const FilterTag: React.FC<IFilterTagProps> = ({ className = '', filter, onDelete }) => {
    const handleDelete = () => {
        if (typeof onDelete !== 'function') return;
        onDelete(filter);
    };
    const t = useTranslations('FilterTag');

    return (
        <div className={`${container} ${className}`}>
            <div className={content}>
                <p className={optionLabel}>{filter.option.label}</p>
            </div>
            <button onClick={handleDelete} className={button} title={t.clear}>
                <CloseIcon className={buttonIcon} />
            </button>
        </div>
    );
};

export default FilterTag;
